import React from 'react';
import { css } from '@emotion/core';
import { H6 } from '../elements/typography.js';
import Thumbnail from './thumbnail';
import NavLink from './nav-link';

const ProjectPreview = ({ slug, title, client, featuredImagePortrait, id }) => {
  const thumbStyles = css(css`
    padding: 0px 8px 56px;
    flex-basis: 100%;
    text-decoration: none;
    width: 100%;

    @media (min-width: 768px) {
      flex-basis: 50%;
    }
  `);
  return (
    <NavLink url={`/work/${slug}/`} css={thumbStyles}>
      {featuredImagePortrait && <Thumbnail image={featuredImagePortrait} />}
      <div
        css={css`
          padding-top: 18px;
        `}
      >
        <H6
          css={css`
            line-height: 1.1;
          `}
        >
          {client}
          <span
            css={css`
              display: block;
            `}
          >
            {title}
          </span>
        </H6>
      </div>
    </NavLink>
  );
};

const ProjectGrid = ({ projects }) => {
  return (
    <div
      css={css`
        display: flex;
        flex-wrap: wrap;
        margin: 0px -8px;
      `}
    >
      {projects &&
        projects.map(project => (
          <ProjectPreview {...project.node} key={project.node.id} />
        ))}
    </div>
  );
};

export default ProjectGrid;
