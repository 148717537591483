import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

import { setColorMode } from '../utilities/functions';

import HeroVideo from '../components/hero-video';
import ProjectGrid from '../components/project-grid';
import ContentEl from '../components/element.js';
import { Container } from '../elements/layouts';
import Footer from '../components/footer';

const Index = ({ data: { homepage, projects }, location }) => {
  const content = homepage.content;

  useEffect(() => {
    setColorMode('/');
  }, []);

  return (
    <>
      <HeroVideo id={homepage.videoId} heroTitle={homepage.videoTitle} />
      <Container>
        <div>
          {content &&
            content.map((el, i) =>
              i === 0 ? <ContentEl key={el.id} el={el} /> : null,
            )}
        </div>

        <ProjectGrid projects={projects.edges} />

        <div>
          {content &&
            content.map((el, i) => {
              return i > 0 ? (
                <ContentEl id={el.id} key={el.id} el={el} />
              ) : null;
            })}
        </div>
      </Container>
      <Footer pathname={location.pathname} />
    </>
  );
};

export default Index;

export const query = graphql`
  query FeaturedProjectsQuery {
    homepage: datoCmsHomepage {
      id
      videoTitle
      videoId
      content {
        ... on DatoCmsImageGrid {
          id
          images {
            alt
            fluid {
              ...GatsbyDatoCmsFluid
            }
          }
        }
        ... on DatoCmsSingleImage {
          id
          image {
            alt
            fluid {
              ...GatsbyDatoCmsFluid
            }
          }
          alignment
        }
        ... on DatoCmsTextBlock {
          id
          heading
          paragraph
        }

        ... on DatoCmsTextBanner {
          id
          text
          linkUrl
          linkText
          textNode {
            childMarkdownRemark {
              htmlAst
            }
          }
        }
      }
    }
    projects: allDatoCmsProject(
      filter: { showOnHome: { eq: true } }
      sort: { fields: [position], order: ASC }
    ) {
      edges {
        node {
          id
          slug
          title
          client
          featuredImagePortrait {
            alt
            fluid(maxWidth: 600, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`;
