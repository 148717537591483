import React, { useState, useEffect, useRef } from 'react';

import { css } from '@emotion/core';
import Player from '@vimeo/player';
// import { throttle } from 'lodash';
// import { setDocHeight } from '../utilities/functions';

import HeroTextBox from './_hero-text-box';
import { H6, P } from '../elements/typography';

const HeroVideo = ({ heroTitle, id, handleLoading }) => {
  const [isMuted, setIsMuted] = useState(true);

  const el = useRef(null);

  let videoContainer = useRef(null);
  let player = useRef(null);
  // let posterImg = useRef(null);

  // useEffect(() => {
  //   setDocHeight(el);
  //   checkSessionStorage();
  //   const throttledFn = throttle(setDocHeight.bind(null, el), 100);

  //   window.addEventListener(
  //     'orientationchange',
  //     () => {
  //       let afterOrientationChange = () => {
  //         throttledFn();
  //         window.removeEventListener('resize', afterOrientationChange);
  //       };
  //       window.addEventListener('resize', afterOrientationChange);
  //     },
  //     false,
  //   );

  //   return () => {
  //     window.removeEventListener('orientationchange', throttledFn, false);
  //   };
  // }, []);

  useEffect(() => {
    if (!player.current) {
      player.current = new Player(videoContainer.current, {
        id: id,
        title: false,
        controls: false,
        byline: false,
        autoplay: true,
        muted: true,
        loop: true,
      });
    }
    player.current.ready().then(function() {});
  }, [id]);

  const toggleVideoPlay = () => {
    if (isMuted) {
      setIsMuted(false);
      player.current.setMuted(false);
    } else {
      setIsMuted(true);
      player.current.setMuted(true);
    }
  };

  return (
    <>
      <div
        ref={el}
        css={css`
          height: 100vh;
          position: relative;
          width: 100vw;
          left: 50%;
          right: 50%;
          margin-left: -50vw;
          margin-right: -50vw;
          overflow: hidden;

          @media (min-width: 768px) {
            min-height: 100vh;
            min-height: -webkit-fill-available;
          }
        `}
      >
        <div
          id="video-wrapper"
          ref={videoContainer}
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            pointer-events: none;
            overflow: hidden;
            iframe {
              width: 100vw;
              height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
              min-height: 100vh;
              min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          `}
        ></div>

        <HeroTextBox>
          <H6>
            <span
              dangerouslySetInnerHTML={{
                __html: heroTitle,
              }}
            ></span>
          </H6>
          <button
            onClick={toggleVideoPlay}
            css={css`
              display: flex;
              align-items: center;
              display: inline-flex;
              background-color: transparent;
              border: none;
            `}
          >
            <P
              style={{
                textTransform: 'uppercase',
                borderBottom: '1px solid white',
              }}
            >
              {isMuted ? 'Unmute' : 'Mute'}
            </P>
          </button>
        </HeroTextBox>
      </div>
    </>
  );
};

export default HeroVideo;
