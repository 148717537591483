import React from 'react';
import { css } from '@emotion/core';
import Image from 'gatsby-image';
import { InView } from 'react-intersection-observer';
import gsap from 'gsap';

const Thumbnail = ({ image }) => {
  const handleReveal = (inView, entry) => {
    if (inView) {
      const tl = new gsap.timeline();
      const el = entry.target.querySelector('.gatsby-image-wrapper');

      tl.to(el, {
        duration: 1.6,
        ease: 'power3.out',
        scale: 1,
        opacity: 1,
      });
    }
  };

  return (
    <InView
      threshold={0.05}
      triggerOnce={true}
      onChange={(inView, entry) => handleReveal(inView, entry)}
    >
      <div
        css={css`
          overflow: hidden;
        `}
      >
        <Image
          fluid={image.fluid}
          alt={image.alt}
          css={css`
            &:hover {
              opacity: 0.7;
            }

            transform: scale(1.2);
            opacity: 0;
            overflow: hidden;
          `}
        />
      </div>
    </InView>
  );
};

export default Thumbnail;
