import styled from '@emotion/styled';

const HeroTextBox = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 30px 15px;
  width: 100%;

  a {
    color: white;
    font-weight: 300;
  }

  h1, h6 {
    color: white;
  }

  @media (min-width: 768px) {
    padding: 30px;
  }
`;

export default HeroTextBox;